import React from 'react';
import PropTypes from 'prop-types';

import { Button, Modal, Loading } from 'murder-hornet-ui';
import { Modal as ContentModal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { useGetTOSQuery, useAcceptTOSMutation, useLogoutMutation } from '../../redux/reducers/auth';

const TOSModal = ({ isOpen, toggle, content }) => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const { data: retrievedTOS = {}, isFetching, isError } = useGetTOSQuery(undefined, { skip: !isLoggedIn });
  const [acceptTOS, { isLoading }] = useAcceptTOSMutation();
  const [logout] = useLogoutMutation();

  // Register Page TOS
  if (!isLoggedIn && content) {
    return (
      <Modal
        isOpen={isOpen}
        onHide={toggle}
        title='Terms of Service'
      >
        <div className='mt-3' dangerouslySetInnerHTML={{ __html: content }} />
      </Modal>
    );
  }

  // Logged In Force Accept TOS
  return (
    <ContentModal
      show
      backdrop='static'
    >
      <ContentModal.Header bsPrefix='modal-header d-flex align-items-start text-break'>
        <ContentModal.Title>
          <span className='me-3' aria-label='Terms of Service'>Terms of Service</span>
        </ContentModal.Title>
      </ContentModal.Header>

      <ContentModal.Body>
        {isFetching
          ? <Loading />
          : isError
            ? <p className='text-center mt-5 mb-3'>An error has occurred, please try again.</p>
            : <div className='mt-3' dangerouslySetInnerHTML={{ __html: retrievedTOS.tos_text }} />
        }
      </ContentModal.Body>

      <ContentModal.Footer>
        <div className='w-100'>
          <Button
            block
            disabled={isFetching || isError}
            loading={isLoading}
            onClick={() => acceptTOS({ tos_processor_uuid: retrievedTOS.uuid })}
          >
            Accept Terms of Service
          </Button>
          <Button block ghost className='mt-1 text-muted' onClick={logout}>Logout</Button>
        </div>
      </ContentModal.Footer>
    </ContentModal>
  );
};

TOSModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  content: PropTypes.string,
};

export default TOSModal;
